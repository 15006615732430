"use client";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { setCartItemQuantity } from "@/lib/centra/selectionFunctions";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";

import useLocation from "../../useLocation";
import optimisticUpdate from "../utils/optimisticUpdate";

export default function useRemoveFromCart(
  callbackFunction?: (newValue: number, itemId: string) => void
) {
  const queryClient = useQueryClient();
  const customerToken = usePersistedStore((state) => state.token);
  const { currency } = useLocation();
  const removeItem = useMutation(
    (variables: { line: string }) =>
      setCartItemQuantity(
        {
          line: variables.line,
          newQuantity: 0,
        },
        customerToken
      ),
    {
      onMutate: async (variables) => {
        if (window.CentraCheckout) window.CentraCheckout.resume();
        await queryClient.cancelQueries({ queryKey: ["selection"] });

        // Snapshot the previous value
        const oldData = queryClient.getQueryData(["selection"]);

        // Optimistically update to the new value
        queryClient.setQueryData(["selection"], (oldCart: any) => {
          return optimisticUpdate(
            oldCart,
            {
              line: variables.line,
              newQuantity: 0,
            },
            currency
          );
        });
        if (callbackFunction) callbackFunction(0, variables.line);
        // Return a context object with the snapshotted value
        return { oldData };
      },
      onError: (error, _variables, context) => {
        if (context?.oldData) {
          queryClient.setQueryData(["selection"], context?.oldData);
        }
      },
      onSettled: () => {
        if (window.CentraCheckout) window.CentraCheckout.resume();
        queryClient.invalidateQueries({ queryKey: ["selection"] });
      },
    }
  );
  return { removeItem };
}

export type RemoveFromCart = ReturnType<typeof useRemoveFromCart>["removeItem"];
