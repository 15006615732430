"use client";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { setPromo } from "@/lib/centra/selectionFunctions";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";

export default function useRemovePromo() {
  const queryClient = useQueryClient();
  const customerToken = usePersistedStore((state) => state.token);
  const removePromo = useMutation(
    (variables: { code: string }) =>
      setPromo({ code: variables.code, remove: true }, customerToken),
    {
      onError: async (error: Error) => {
        return error;
      },
      onSuccess: async (data) => {
        queryClient.setQueryData(["selection"], data);
      },
    }
  );
  return { removePromo };
}
