"use client";

import useModal from "../../useModal";
import useSelection from "../useSelection";
import useRemoveFromCart from "./removeFromCart";
import useRemovePromo from "./removePromo";
import useUpdateQuantity from "./updateQuantity";
import { useAddPromo } from "./useAddPromo";
import useAddToCart from "./useAddToCart";
import { useFormatItems } from "./useItems";

const useCart = () => {
  const { cart, isLoading } = useSelection();

  const modal = useModal("cart", () => {}, true);
  const { addToCart } = useAddToCart();
  const { addPromo } = useAddPromo();
  const { removePromo } = useRemovePromo();
  const { removeItem } = useRemoveFromCart();
  const { updateQuantity } = useUpdateQuantity();
  const { formattedItems: items } = useFormatItems(cart?.items);

  return {
    cart: cart,
    items,
    isLoading,
    addItem: addToCart,
    addPromo,
    removePromo,
    removeItem,
    updateQuantity,
    modal,
    summary: cart?.summary,
  };
};

export type CartHook = ReturnType<typeof useCart>;

export default useCart;

// const updateBuffer = (variables: {
//   itemId: string;
//   cartItem: Cart["items"][number];
// }) => {
//   const newGrandTotal =
//     (typeof buffer?.summary?.prices?.grandTotal === "string"
//       ? parseInt(buffer?.summary?.prices?.grandTotal ?? "0")
//       : buffer?.summary?.prices?.grandTotal ?? 0) +
//     (variables?.cartItem?.priceAsNumber ?? 0);
//   const newTotal =
//     (typeof buffer?.summary?.prices?.grandTotal === "string"
//       ? parseInt(buffer?.summary?.prices?.grandTotal ?? "0")
//       : buffer?.summary?.prices?.grandTotal ?? 0) +
//     (variables?.cartItem?.priceAsNumber ?? 0);
//   const newShippingAsNumber =
//     buffer?.summary?.prices?.shippingAsNumber ?? 0 > 0
//       ? buffer?.summary?.prices?.shippingAsNumber
//       : newGrandTotal > 2000
//       ? 0
//       : 250;
//   if (setBuffer)
//     setBuffer({
//       ...buffer,
//       items: [...(buffer?.items ?? []), variables.cartItem],
//       // @ts-ignore
//       summary: {
//         ...buffer?.summary,
//         prices: {
//           ...buffer?.summary?.prices,
//           total: newTotal.toString(),
//           totalAsNumber: newTotal,
//           grandTotal: newGrandTotal.toString(),
//           shipping: (newShippingAsNumber ?? "0").toString(),
//           shippingAsNumber: newShippingAsNumber,
//         },
//       },
//     });
// };
