import type { CartItem } from "../cart/useItems";
import type { UseSelectionReturn } from "../useSelection";

export const getTotalQuantity = (
  items: UseSelectionReturn["selection"]["items"],
  variables?: { newQuantity: number; line: string }
) =>
  items?.reduce((acc: number, item: any) => {
    if (!variables) return acc + item.quantity;
    if (item.line === variables?.line) {
      return acc + variables.newQuantity;
    }
    return acc + item.quantity;
  }, 0);

export const getTotalPrice = (
  items: UseSelectionReturn["selection"]["items"],
  variables?: { newQuantity: number; line: string }
): number =>
  items?.reduce((acc: number, item: any) => {
    if (!variables) return acc + item.quantity * item.priceAsNumber;
    if (item.line === variables?.line) {
      return acc + item.priceAsNumber * variables.newQuantity;
    }
    return acc + item.quantity * item.priceAsNumber;
  }, 0) || 0;

export const getGrandTotalPrice = (
  items: UseSelectionReturn["selection"]["items"],
  shippingPriceAsNumber: number = 0
) => getTotalPrice(items) + shippingPriceAsNumber;

export const formatPrice = (price: number, currency: string) => {
  const stringPrice = price.toString();
  const priceLenght = stringPrice.length;
  const pricewithSpace =
    stringPrice.slice(0, priceLenght - 3) +
    " " +
    stringPrice.slice(priceLenght - 3, priceLenght);
  return `${pricewithSpace} ${currency}`;
};

const optimisticUpdate = (
  data: UseSelectionReturn,
  variables: { newQuantity: number; line: string },
  currency?: string
) => {
  const optimisticData = {
    ...data,
    selection: {
      ...data?.selection,
      summary: {
        ...data?.selection?.summary,
        totalQuantity: getTotalQuantity(data?.selection?.items, variables),
        prices: {
          ...data?.selection?.summary?.prices,
          total: formatPrice(
            getTotalPrice(data?.selection?.items, variables) || 0,
            currency || ""
          ),
          grandTotal: formatPrice(
            (getTotalPrice(data?.selection?.items, variables) || 0) +
              (data?.selection?.summary?.prices?.shippingAsNumber || 0),
            currency || ""
          ),
        },
      },
      items: data?.selection?.items
        ?.map((item: any) => {
          if (item.line === variables.line) {
            if (variables.newQuantity === 0) return null;
            return {
              ...item,
              quantity: variables.newQuantity,
            };
          }
          return item;
        })
        .filter((item: any) => item !== null),
    },
  };
  return optimisticData;
};

export const optimisticAddToCart = (
  cart: UseSelectionReturn,
  variables: {
    itemId: string;
    cartItem: CartItem;
  },
  currency?: string
) => {
  const { itemId, cartItem } = variables;
  
  const { selection, ...rest } = cart;
  const { items } = selection;
  const foundItem = items?.find((item: any) => item.itemId === itemId);
  const newItems = items?.filter((item: any) => item.itemId !== itemId) || [];

  let item = {
    ...cartItem,
    quantity: 1,
  };

  if (foundItem) {
    item = {
      ...foundItem,
      quantity: foundItem.quantity + 1,
    };
  }

  newItems.push(item);

  if (item)
    return {
      ...rest,
      selection: {
        ...selection,
        summary: {
          ...selection.summary,
          totalQuantity: getTotalQuantity(newItems),
          prices: {
            ...selection.summary.prices,
            total: formatPrice(getTotalPrice(newItems), currency || ""),
            grandTotal: formatPrice(
              getGrandTotalPrice(
                newItems,
                selection?.summary?.prices?.shippingAsNumber
              ),
              currency || ""
            ),
          },
        },

        items: newItems,
      },
    };

  return {
    ...rest,
    selection: {
      ...selection,
      summary: {
        ...selection.summary,
        totalQuantity: getTotalQuantity(newItems),
        prices: {
          ...selection.summary.prices,
          total: formatPrice(getTotalPrice(newItems), currency || ""),
          grandTotal: formatPrice(
            getGrandTotalPrice(
              newItems,
              selection?.summary?.prices?.shippingAsNumber
            ),
            currency || ""
          ),
        },
      },
      items: newItems,
    },
  };
};

export default optimisticUpdate;
