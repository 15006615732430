"use client";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { changePassword } from "@/lib/centra/selectionFunctions";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";

export default function useUpdatePassword() {
  const queryClient = useQueryClient();
  const customerToken = usePersistedStore((state) => state.token);
  const updatePassword = useMutation(
    (variables: {
      oldPassword: string;
      newPassword: string;
      confirmPassword: string;
    }) => changePassword(variables, customerToken),
    {
      onError: async (error: Error) => {
        if (window.CentraCheckout) window.CentraCheckout.resume();
        return error;
      },
      onSuccess: async (data) => {
        queryClient.setQueryData(["selection"], data);
        queryClient.invalidateQueries(["selection"]);
      },
    }
  );
  return { updatePassword };
}
