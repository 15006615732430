import type { StateCreator } from "zustand";

import type { ISessionState } from "./sessionStore";

export interface IModalState {
  modalId: string;
  setModal: (modalId: string, modalCallback: () => void) => void;
  clearModal: () => void;
  modalCallback: () => void;
}

const createModalSlice: StateCreator<ISessionState, [], [], IModalState> = (
  set,
  _get
) => ({
  modalId: "",
  modalCallback: () => {},
  setModal: (modalId: string, modalCallback) =>
    set((oldState) => {
      oldState.modalCallback();
      return { ...oldState, modalId, modalCallback };
    }),
  clearModal: () =>
    set((oldState) => {
      oldState.modalCallback();
      return { ...oldState, modalCallback: () => {}, modalId: "" };
    }),
});

export default createModalSlice;
