const priceListId = 21; // NOK
const variantsPerDisplay = "single";
const wishlist = "local";

const animationDurationContent = 0.8;
const animationDurationModal = 0.5;
const animationDelay = 0.2;

const config = {
  priceListId,
  variantsPerDisplay,
  wishlist,
  animationDurationContent,
  animationDurationModal,
  animationDelay,
};

export default config;
