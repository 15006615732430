import { useCallback, useEffect, useState } from "react";

function useLockBodyScroll(lockedOnDesktop: boolean | undefined = false) {
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [hydrated, setHydrated] = useState<boolean>();

  useEffect(() => {
    setHydrated(true);

    return () => {
      setHydrated(false);
      document.body.classList.remove("scroll-locked");
      if (lockedOnDesktop) {
        document.body.classList.remove("desktop-locked");
      }
    };
  }, [lockedOnDesktop]);

  const lockBodyScroll = useCallback(() => {
    if (!hydrated) return;
    setIsLocked(true);
    document.body.classList.add("scroll-locked");
    if (lockedOnDesktop) {
      document.body.classList.add("desktop-locked");
    }
  }, [hydrated, lockedOnDesktop]);

  const unlockBodyScroll = useCallback(() => {
    if (!hydrated) return;
    setIsLocked(false);
    document.body.classList.remove("scroll-locked");
    if (lockedOnDesktop) {
      document.body.classList.remove("desktop-locked");
    }
  }, [hydrated, lockedOnDesktop]);

  const toggleBodyScroll = useCallback(() => {
    if (isLocked) {
      lockBodyScroll();
    } else {
      unlockBodyScroll();
    }
  }, [isLocked, lockBodyScroll, unlockBodyScroll]);

  return { isLocked, unlockBodyScroll, lockBodyScroll, toggleBodyScroll };
}

export default useLockBodyScroll;
