"use client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useRouter } from "next/navigation";

import { loginUser } from "@/lib/centra/selectionFunctions";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";

export default function useLogin() {
  const queryClient = useQueryClient();
  const customerToken = usePersistedStore((state) => state.token);
  const router = useRouter();

  const login = useMutation(
    (variables: { email: string; password: string }) =>
      loginUser(variables, customerToken),
    {
      onError: async (error: Error) => {
        if (window.CentraCheckout) window.CentraCheckout.resume();
        return error;
      },
      onSuccess: async (data) => {
        queryClient.setQueryData(["selection"], data);

        Cookies.set("token", data.token, {
          expires: 365,
          secure: true,
          sameSite: "strict",
        });
        queryClient.invalidateQueries(["selection"]);
        router.refresh();
      },
    }
  );

  return { login };
}
