"use client";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { setPromo } from "@/lib/centra/selectionFunctions";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";

export const useAddPromo = () => {
  const queryClient = useQueryClient();
  const customerToken = usePersistedStore((state) => state.token);
  const addPromo = useMutation(
    (variables: { code: string; remove: boolean }) =>
      setPromo(variables, customerToken),
    {
      onError: async (error: Error) => {
        return error;
      },
      onSuccess: async (data) => {
        queryClient.setQueryData(["selection"], data);
      },
    }
  );

  return { addPromo };
};
