"use client";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { setCartItemQuantity } from "@/lib/centra/selectionFunctions";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";

import useLocation from "../../useLocation";
import optimisticUpdate from "../utils/optimisticUpdate";

export default function useUpdateQuantity() {
  const queryClient = useQueryClient();
  const customerToken = usePersistedStore((state) => state.token);
  const { currency } = useLocation();
  const updateQuantity = useMutation(
    (variables: { line: string; newQuantity: number }) =>
      setCartItemQuantity(variables, customerToken),
    {
      onMutate: async (variables) => {
        if (window.CentraCheckout) window.CentraCheckout.suspend();
        await queryClient.cancelQueries({ queryKey: ["selection"] });

        // Snapshot the previous value
        const oldData = queryClient.getQueryData(["selection"]);

        // // Optimistically update to the new value
        queryClient.setQueryData(["selection"], (oldCart: any) => {
          return optimisticUpdate(
            oldCart,
            {
              line: variables.line,
              newQuantity: variables.newQuantity,
            },
            currency 
          );
        });

        // Return a context object with the snapshotted value
        return { oldData };
      },
      onError: (error, _variables, context) => {
        if (context?.oldData) {
          queryClient.setQueryData(["selection"], context?.oldData);
        }
      },
      onSettled: () => {
        if (window.CentraCheckout) window.CentraCheckout.suspend();
        queryClient.invalidateQueries({ queryKey: ["selection"] });
      },
    }
  );
  return { updateQuantity };
}

export type UpdateQuantity = ReturnType<
  typeof useUpdateQuantity
>["updateQuantity"];
