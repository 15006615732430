import { create } from "zustand";

import type { IModalState } from "./modal";
import createModalSlice from "./modal";
import type { IScrollState } from "./sessionSlices/scrollSlice";
import createScrollSlice from "./sessionSlices/scrollSlice";

export type ISessionState = IScrollState & IModalState;

const useSessionStore = create<ISessionState>()((...a) => ({
  ...createScrollSlice(...a),
  ...createModalSlice(...a),
}));

export default useSessionStore;
