import type { StateCreator } from "zustand";

import type { ISessionState } from "../sessionStore";

export interface IScrollState {
  scrollDirection: "up" | "down";
  setScrollUp: () => void;
  setScrollDown: () => void;
  atTop: boolean;
  setAtTop: (atTop: boolean) => void;
}

const createHasScrolledSlice: StateCreator<
  ISessionState,
  [],
  [],
  IScrollState
> = (set, _get) => ({
  scrollDirection: "up",
  setScrollUp: () => {
    set((oldState) => ({ ...oldState, scrollDirection: "up" }));
  },
  setScrollDown: () => {
    set((oldState) => ({ ...oldState, scrollDirection: "down" }));
  },
  atTop: true,
  setAtTop: (atTop: boolean) => {
    set((oldState) => ({ ...oldState, atTop }));
  },
});

export default createHasScrolledSlice;
