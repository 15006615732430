import useWishlist from "../../useWishlist";
import type { Cart } from "../useSelection";
import useSelection from "../useSelection";
import useRemoveFromCart from "./removeFromCart";
import useUpdateQuantity from "./updateQuantity";
export type CartItem = Cart["items"][number];

const formatItem = (
  item: CartItem,
  remove: (item: CartItem) => void,
  update: (item: CartItem, newQuantity: number) => void,
  increment: (item: CartItem) => void,
  decrement: (item: CartItem) => void,
  moveToWishlist: (item: CartItem) => void
) => {
  return {
    ...item,

    remove: () => remove(item),
    update: (newQuantity: number) => update(item, newQuantity),
    increment: () => {
      increment(item);
    },
    decrement: () => {
      decrement(item);
    },
    moveToWishlist: () => {
      moveToWishlist(item);
    },
    inCart: item.quantity > 0,
  };
};

export const useFormatItems = (items: CartItem[]) => {
  const { removeItem } = useRemoveFromCart();
  const { updateQuantity } = useUpdateQuantity();
  const { add } = useWishlist();
  const remove = (item: CartItem) =>
    removeItem.mutate({
      line: item.id,
    });

  const update = (item: CartItem, newQuantity: number) => {
    updateQuantity.mutate({
      line: item.id,
      newQuantity,
    });
  };

  const increment = (item: CartItem) => {
    update(item, item.quantity + 1);
  };

  const decrement = (item: CartItem) => {
    update(item, item.quantity - 1);
  };

  const moveToWishlist = (item: CartItem) => {
    remove(item);
    add(item.itemId);
  };

  const formattedItems = items.map((item) => {
    return formatItem(
      item,
      remove,
      update,
      increment,
      decrement,
      moveToWishlist
    );
  });

  return { formattedItems };
};

const useItems = () => {
  const { cart } = useSelection();
  const items = cart?.items;
  const { formattedItems } = useFormatItems(items);

  return { cartItems: formattedItems };
};

export default useItems;

export type CartItemWithHooks = ReturnType<typeof formatItem>;
export type CartItemMaybeHooked = CartItem | CartItemWithHooks;
