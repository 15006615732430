"use client";

import modal from "@/lib/stateManagement/sessionState/modal";

import { useSelection } from "../useSelection";

export type CountryType = {
  eu: boolean;
  changeLocation: (stateId?: string) => void;
  selected: boolean;
  code: string;
  currency: string;
  default: boolean;
  country: string;
  language: string;
  name: string;
  pricelist: string;
  states?: {
    state: string;
    name: string;
  }[];
};

const useLocation = () => {
  const { data, isLoading, refetch } = useSelection();

  const languages = data?.languages;
  const countries = data?.countries.map((country) => ({
    ...country,
    name: country?.name,
  }));

  const currency = data?.selection?.currency;
  const location = data?.location?.country;
  const country = data?.location?.name;
  const pricelist = data?.location?.pricelist || "23";
  const language =
    languages &&
    languages
      .map((lang) => {
        if (lang?.default) {
          return lang?.language?.toUpperCase();
        }
      })
      .filter((lang) => lang !== undefined)[0];
  const newCountries = countries?.map((country) => {
    return {
      ...country,
      selected: country?.country === location,
    };
  }) as unknown as CountryType[];
  const currentCountry = newCountries?.find((country) => country.selected);
  const states = currentCountry?.states || [];
  const state = data?.location?.state as string | undefined;

  return {
    location,
    country,
    languages,
    countries: newCountries || undefined,
    currency,
    language,
    modal,
    pricelist,
    states,

    currentCountry,
    state,
    refetch,
    isLoading,
  };
};

export default useLocation;
